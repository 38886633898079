<div class="bg-white">
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: justify;
    "
  >
    <span style="font-size: 13px; font-family: Verdana, sans-serif"
      >&nbsp;</span
    >
  </p>
  <!-- <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: justify;
    "
  >
    <span style="font-size: 13px; font-family: Verdana, sans-serif"
      >(deberá poder accederse a ella, al hacer clic en cada&nbsp;</span
    ><a
      href="https://viajes.catalogodepremios.com/#inner_2"
      style="color: rgb(149, 79, 114); text-decoration: underline"
      ><span
        style="font-size: 17px; font-family: Lato, sans-serif; color: green"
        >+ info</span
      ></a
    ><span
      style="
        font-size: 13px;
        font-family: Lato, sans-serif;
        color: rgb(51, 51, 51);
      "
      >&nbsp;</span
    ><span style="font-size: 13px; font-family: Verdana, sans-serif"
      >de la información básica, y al hacer clic sobre los enlaces que se llamen
      Política de Privacidad del web)</span
    >
  </p> -->
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: justify;
    "
  >
    <span style="font-size: 13px; font-family: Verdana, sans-serif"
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: justify;
    "
  >
    <span style="font-size: 13px; font-family: Verdana, sans-serif"
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >Política de Privacidad</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >&nbsp;</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >1.-&nbsp;¿Cuál es la empresa responsable del tratamiento de sus
        datos?</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >La empresa responsable del tratamiento de sus datos es
      <strong
        ><span style="font-family: Lato, sans-serif"
          >E-RETAIL ADVERTISING, S.L.</span
        ></strong
      >
      (en adelante, “<em
        ><strong
          ><span style="font-family: Lato, sans-serif"
            >E-RETAIL ADVERTISING</span
          ></strong
        ></em
      >”) NIF número B85442283 y domicilio social en Madrid, en Calle Ayala 85,
      1ºC e inscrita en el Registro Mercantil de Madrid, Tomo 25591 Folio 200
      con Hoja M- 461231&nbsp;</span
    >
  </p>
  <p>
    <span style="font-family: Lato, sans-serif"
      >Datos de contacto del Delegado de protección de datos:
      dpo@e-retailadvertising.com&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >El Usuario podrá contactar con E-RETAIL ADVERTISING mediante correo
      electrónico: contacto@e-retailadvertising.com</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >2.- Recomendaciones</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Por favor lea detenidamente y siga las siguientes recomendaciones:</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >El Sitio Web no está dirigido a menores de edad. Por favor active el
      control parental para prevenir y controlar el acceso de menores de edad a
      Internet e informar a los menores de edad sobre aspectos relativos a la
      seguridad.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Mantenga en su equipo un software antivirus instalado y debidamente
      actualizado, que garantice que su equipo se encuentra libre de software
      maligno, así como de aplicaciones spyware que pongan en riesgo su
      navegación en Internet, y en peligro la información alojada en el
      equipo.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Revise y lea los textos legales, así como la presente Política de
      Privacidad que E-RETAIL ADVERTISING pone a su disposición en el Sitio
      Web.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >3.- ¿Por qué E-RETAIL ADVERTISING está legitimada para llevar a cabo el
        tratamiento de sus datos?</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING tiene legitimación para el tratamiento de sus datos
      para poder llevar a cabo la prestación de los servicios que haya
      solicitado a través del Sitio Web (contrato) y en algunos casos por el
      consentimiento que haya prestado en el momento de su registro en el Sitio
      Web. En ocasiones la base jurídica para el tratamiento será el interés
      legítimo de nuestra entidad en su actividad de marketing directo y en la
      solicitud para elaboración de encuestas. Puede consultar en el apartado
      siguiente la base jurídica de cada tratamiento de datos.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Su consentimiento es siempre revocable.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING se toma muy en serio la protección de su privacidad
      y de sus datos personales. Por lo tanto, su información personal se
      conserva de forma segura y se trata con el máximo cuidado.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >4.- ¿Cuáles son&nbsp;las</span
      ></strong
    ><span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;<strong
        ><span style="font-family: Lato, sans-serif"
          >finalidades del tratamiento de sus datos de carácter personal?</span
        ></strong
      ></span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >De conformidad con lo establecido el Reglamento (UE) 2016/679 del
      Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la
      protección de las personas físicas en lo que respecta al tratamiento de
      datos personales y a la libre circulación de estos datos, y de acuerdo a
      la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
      Personales y garantías digitales, le informamos que sus datos personales
      serán tratados con las finalidades que se relacionan a continuación:</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >4.a) Tratamiento de datos de carácter personal por parte de E-RETAIL
      ADVERTISING</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Sus datos de carácter personal recabados por E-RETAIL ADVERTISING, podrán
      ser utilizados para las siguientes finalidades:</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >(i) Prestar los servicios solicitados por el Usuario en el Sitio Web.
      Base jurídica: la relación establecida entre las partes, contrato.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >(ii) Ceder los datos a las empresas de energía, para poder comparar sus
      tarifas actuales con otras tarifas más ventajosas, según el formulario
      rellenado por usted, y ofrecerle el resultado del comparador de energía;
      Base jurídica: consentimiento</span
    >

    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >(iii) Realizar comunicaciones comerciales. Base jurídica: interés
      legítimo en caso en que proceda tras la oportuna ponderación.</span
    >

    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >(iv) Realizar encuestas para la mejora de la calidad en el servicio. Base
      jurídica: interés legítimo en caso en que proceda tras la oportuna
      ponderación.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm 3.6pt 35.4pt;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <!-- <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Asimismo, el usuario consiente el tratamiento de sus datos para la
      elaboración de perfiles, así como para la segmentación de sus datos. Los
      citados tratamientos pueden tener como finalidad tanto el análisis y
      realización de estadísticas para conocer el tráfico y utilización del
      Sitio Web por parte de los Usuarios, como la determinación de sus gustos y
      preferencias para remitirle información promocional acorde con sus
      intereses. Base jurídica: consentimiento</span
    >
  </p> -->
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >4.b) Pasados algunos meses desde la cesión de sus datos a empresas del
      sector energía, es posible que cedamos sus datos a empresas de los
      sectores de actividad que contiene esta política de privacidad,
      relacionadas en este mismo apartado, para que por parte de dichas empresas
      procedan a la remisión de comunicaciones comerciales. Base jurídica:
      consentimiento</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING podrá tratar sus datos, siempre que disponga del
      consentimiento y cuando haya aceptado el tratamiento que permitirá que
      pueda recibir por medio de cualquier medio como podría ser por carta,
      llamada telefónica, correo electrónico, SMS/MMS, o por otros medios de
      comunicación electrónica equivalentes, comunicaciones comerciales o
      información de empresas o categorías de empresas cesionarias relacionadas
      con los siguientes sectores de actividad. En caso en que no desee recibir
      comunicaciones comerciales de alguno o algunos sectores de actividad de
      los que se muestran a continuación, haga clic sobre el sector sobre el que
      no desea recibir comunicaciones comerciales o haga clic sobre la opción
      MARCAR TODOS (al hacer clic sobre esta opción, se marcarán todos los
      sectores de actividad).</span
    >
  </p>
  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheckAll"
      (click)="checkAll()"
    />
    <label class="custom-control-label" for="customCheckAll"
      >Marcar Todos</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck1"
    />
    <label class="custom-control-label" for="customCheck1"
      >Asistencia Legal: Abogacía</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck2"
    />
    <label class="custom-control-label" for="customCheck2">Automoción</label>
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck3"
    />
    <label class="custom-control-label" for="customCheck3"
      >Consumo, Comida y Restauración</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck4"
    />
    <label class="custom-control-label" for="customCheck4">Dating</label>
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck5"
    />
    <label class="custom-control-label" for="customCheck5"
      >Deportes y Gaming</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck6"
    />
    <label class="custom-control-label" for="customCheck6"
      >Empleo y Formación</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck7"
    />
    <label class="custom-control-label" for="customCheck7"
      >Empresas de Seguridad</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck8"
    />
    <label class="custom-control-label" for="customCheck8"
      >Energías: Luz, Gas, Climatización, Combustibles</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck9"
    />
    <label class="custom-control-label" for="customCheck9"
      >Financiero: Productos financieros, Bancos e Inversión</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck10"
    />
    <label class="custom-control-label" for="customCheck10"
      >Hogar: Decoración, Reformas y Electrodomésticos</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck11"
    />
    <label class="custom-control-label" for="customCheck11"
      >Mamás y Bebés</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck12"
    />
    <label class="custom-control-label" for="customCheck12"
      >Ocio: Cine, Teatro</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck13"
    />
    <label class="custom-control-label" for="customCheck13"
      >Ofertas, e-commerce y Cupones</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck14"
    />
    <label class="custom-control-label" for="customCheck14">ONGs</label>
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck15"
    />
    <label class="custom-control-label" for="customCheck15"
      >Publicaciones, Encuestas y Opinión</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck16"
    />
    <label class="custom-control-label" for="customCheck16"
      >Salud y Medicina Alternativas</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck17"
    />
    <label class="custom-control-label" for="customCheck17"
      >Seguros: Decesos, Dental, Salud, Automoción, Hogar, Vida, Viajes,
      Autónomos, Accidentes, Genéricos</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck18"
    />
    <label class="custom-control-label" for="customCheck18"
      >Sorteos, Concursos y Juegos de Azar</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck19"
    />
    <label class="custom-control-label" for="customCheck19"
      >Telecomunicaciones: Productos y Servicios de telecomunicaciones y
      tecnología.</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck20"
    />
    <label class="custom-control-label" for="customCheck20"
      >Turismo y Fotografía</label
    >
  </div>

  <div class="custom-control custom-checkbox m-1">
    <input
      type="checkbox"
      class="custom-control-input"
      checked
      id="customCheck21"
    />
    <label class="custom-control-label" for="customCheck21">Videncia</label>
  </div>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >No obstante, el Usuario podrá revocar el consentimiento, en cada
      comunicado comercial o publicitario que se le haga llegar, y en cualquier
      momento, mediante notificación en la siguiente dirección de correo
      electrónico baja@e-retailadvertising.com o mediante carta dirigida a la
      Calle Ayala 85, 1ºC 28006 Madrid.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >5.- Cesiones de datos</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <ol
    start="1"
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="a"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Cesiones a empresas del sector energía:</span
      >
    </li>
  </ol>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Sus datos serán cedidos a empresas del sector energía para los fines de
      comparador de energías, cuyas políticas de privacidad podrá consultar en
      un enlace habilitado en el formulario de recogida de datos</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <!-- <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
    "
  >
    <strong
      ><span style="font-size: 13px; font-family: Lato, sans-serif; color: red"
        >EMPRESA 1, S.L.</span
      ></strong
    ><span style="font-size: 13px; font-family: Lato, sans-serif; color: red"
      ><br />NIF:&nbsp;<br />Domicilio<br />Municipio:<br />Código postal:<br /><br
    /></span>
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: center;
    "
  >
    <a href="" style="color: rgb(149, 79, 114); text-decoration: underline"
      ><span style="font-size: 11px; font-family: Lato, sans-serif; color: red"
        >Política de privacidad</span
      ></a
    ><span style="font-size: 13px; font-family: Lato, sans-serif; color: red"
      >&nbsp;(añadir enlace a la URL de la política de privacidad)</span
    >
  </p> -->
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <ol
    start="2"
    style="
      margin-bottom: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      margin-top: 0cm;
    "
    type="a"
  >
    <li
      style="
        margin: 0cm;
        font-size: 16px;
        font-family: 'Times New Roman', serif;
        color: #444444;
        background: #f9f9f9;
      "
    >
      <span style="font-size: 15px; font-family: Lato, sans-serif"
        >Cesiones a terceras empresas:</span
      >
    </li>
  </ol>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Sus datos podrán ser cedidos a empresas relacionadas con los sectores y
      categorías de cesionarios que se relacionan y detallan en el punto
      4.b).</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >La finalidad de la cesión será el envío, por carta, teléfono, correo
      electrónico, SMS/MMS, o por otros medios de comunicación electrónica
      equivalentes por parte de estas empresas de información y comunicaciones
      comerciales que puedan resultar de su interés.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >6.- Conservación de los datos</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Los datos recabados por E-RETAIL ADVERTISING se conservarán mientras se
      mantenga la relación contractual o de consentimiento entre las partes,
      siempre y cuando no se solicite la supresión, negativa o baja de dichos
      datos por el Usuario.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Se informa al Usuario que sus datos serán recogidos únicamente para los
      fines expuestos en los párrafos anteriores y no serán tratados de manera
      incompatible con dichos fines.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >7.- Veracidad de los datos.</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >El Usuario garantiza que los datos personales facilitados son veraces y
      se hace responsable de comunicar a E-RETAIL ADVERTISING, cualquier
      modificación de los mismos. El usuario responderá, en cualquier caso, de
      la veracidad de los datos facilitados, reservándose E-RETAIL ADVERTISING,
      el derecho a excluir de los servicios registrados a todo usuario que haya
      facilitado datos falsos, sin perjuicio de las demás acciones que procedan
      en Derecho.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >8.- Medidas de Seguridad</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING dispone de un Registro de Actividades de
      Tratamiento, conoce sus obligaciones en caso de necesidad de una
      Evaluación de Impacto de Protección de Datos, conoce los procesos en caso
      de violación de seguridad, mantiene los niveles de seguridad de protección
      de datos personales, conforme a la normativa en materia de protección de
      datos, y ha establecido todos los medios técnicos a su alcance del Art. 25
      y 32 del Reglamento (UE) 2016/679, para evitar la pérdida, mal uso,
      alteración, acceso no autorizado y robo de los datos que el usuario
      facilite a través del Sitio Web, sin perjuicio de informarle de que las
      medidas de seguridad en Internet no son inexpugnables. E-RETAIL
      ADVERTISING ha examinado el riesgo y en base a él, ha establecido medidas
      encaminadas a garantizar la integridad, confidencialidad, disponibilidad y
      resiliencia, y se compromete a cumplir con el deber de restaurar la
      disponibilidad y acceso en caso de incidente físico o técnico, y de
      implementar un proceso de verificación, evaluación y valoración regular de
      la eficacia de las medidas técnicas y organizativas respecto de los datos
      personales contenidos en el fichero automatizado de acuerdo con la
      legislación aplicable, así como a conferirles un tratamiento seguro en las
      cesiones que, en su caso, puedan producirse.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Los datos de contacto del Delegado de protección de datos son:
      dpo@e-retailadvertising.com</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >9.- Enlaces</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Este Sitio Web puede contener enlaces a otros sitios web. Tenga en cuenta
      que no somos responsables del tratamiento de datos que puedan realizar los
      titulares de otros sitios web. La presente Política de Privacidad se
      aplica exclusivamente a la información que recopilamos en este Sitio Web.
      Le recomendamos que lea las políticas de privacidad de otros sitios web
      con los que enlace desde nuestro Sitio Web o que visite de cualquier otro
      modo.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >10.- ¿Qué derechos tiene el usuario en relación con sus datos?</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Ponemos en conocimiento del Usuario que podrá ejercer, en cualquier
      momento, sus derechos de acceso, rectificación, supresión, oposición,
      limitación, portabilidad u olvido, incluido el derecho a no verse inmerso
      en decisiones automatizadas, al tratamiento de sus datos mediante la
      remisión de una comunicación escrita, a la siguiente dirección:
      Departamento legal de E-RETAIL ADVERTISING, S.L., C/Ayala 85, 1ºC, 28006
      Madrid, pudiendo interponer una reclamación ante la Autoridad de control,
      o bien enviando un correo electrónico a la siguiente dirección:
      contacto@e-retailadvertising.com</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >11.- Preguntas</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Si tiene alguna pregunta sobre la presente Política de Privacidad, le
      rogamos que se ponga en contacto con nosotros enviando un correo
      electrónico a la dirección contacto@e-retailadvertising.com</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >12.- Cambios</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >E-RETAIL ADVERTISING se reserva el derecho de revisar su Política de
      Privacidad en el momento que lo considere oportuno. Por esta razón, le
      rogamos que compruebe de forma regular esta Política de Privacidad para
      leer la versión más reciente publicada por parte de E-RETAIL
      ADVERTISING.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >&nbsp;</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <strong
      ><span
        style="
          font-size: 15px;
          font-family: Lato, sans-serif;
          color: rgb(68, 68, 68);
        "
        >13.- Transferencias Internacionales de Datos</span
      ></strong
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Solo se realizarán transferencias de datos personales que sean objeto de
      tratamiento o vayan a serlo tras su transferencia a un tercer país si, el
      responsable y el encargado del tratamiento cumplen las condiciones
      establecidas en el Reglamento (UE) 2016/679, General de Protección de
      Datos (RGPD), a fin de asegurar que el nivel de protección de las personas
      físicas garantizado por dicho Reglamento no se vea menoscabado.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >A este respecto, es posible que se realicen transferencias de datos
      personales fuera de la UE, y en concreto a Marruecos, a los efectos de que
      alguna entidad cesionaria de los datos pueda prestar un servicio encargado
      a una entidad con sede en Marruecos, que actúa como encargada del
      tratamiento. Más información sobre los detalles de la transferencia
      internacional, escribiendo al e-mail de contacto.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 3.6pt 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-align: start;
      text-indent: 0px;
      text-decoration: none;
      background-color: rgb(249, 249, 249);
    "
  >
    <span
      style="
        font-size: 15px;
        font-family: Lato, sans-serif;
        color: rgb(68, 68, 68);
      "
      >Como para ese tercer país, no existe decisión de adecuación a que se
      refiere el Art. 45 del RGPD, se cumplen con las garantías adecuadas de
      haber celebrado un contrato con cláusulas contractuales tipo del Art. 46.2
      del RGPD, pudiendo solicitar evidencias de dichas garantías en el correo
      electrónico de contacto.</span
    >
  </p>
  <p
    style="
      margin-right: 0cm;
      margin-left: 0cm;
      font-size: medium;
      font-family: 'Times New Roman', serif;
      margin: 0cm;
      color: rgb(0, 0, 0);
      font-style: normal;
      font-weight: 400;
      text-indent: 0px;
      text-decoration: none;
      text-align: justify;
    "
  >
    <span style="font-size: 13px; font-family: Verdana, sans-serif"
      >&nbsp;</span
    >
  </p>
</div>
