<header class="header-area overlay">
  <nav class="navbar navbar-expand-md">
    <div class="container">
      <img
        src="https://ad.uinterbox.com/con18298-logo/image019.png"
        class="navbar-brand img-fluid"
      />
      <strong class="text-bolder text-black"
        >¡Tu recomendación de manera gratuita en menos de 1 minuto!</strong
      >
    </div>
  </nav>
</header>
<div class="vh-100">
  <router-outlet></router-outlet>
</div>
<script type="text/javascript">
  document.addEventListener("DOMContentLoaded", function (event) {
    window._tfa = window._tfa || [];
    window._tfa.push({ notify: "event", name: "page_view", id: 1757722 });
    !(function (t, f, a, x) {
      if (!document.getElementById(x)) {
        t.async = 1;
        t.src = a;
        t.id = x;
        f.parentNode.insertBefore(t, f);
      }
    })(
      document.createElement("script"),
      document.getElementsByTagName("script")[0],
      "//cdn.taboola.com/libtrc/unip/1757722/tfa.js",
      "tb_tfa_script"
    );
  });
</script>
