<div class="container h-100 d-flex align-items-center justify-content-center">
  <!-- Modern Vertical Wizard -->
  <div class="card w-100" [class]="{ 'd-none': isSubmited }">
    <section class="w-100">
      <div
        id="stepper4"
        class="bs-stepper vertical wizard-modern modern-vertical-wizard-example d-flex align-items-center"
      >
        <div class="bs-stepper-header">
          <!-- <div class="step" data-target="#account-details-vertical-modern">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-circle"> </span>
            </button>
          </div>
          <div class="bs-stepper-line"></div> -->
          <div class="step" data-target="#personal-info-vertical-modern">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-circle"> </span>
            </button>
          </div>
          <div class="bs-stepper-line"></div>
          <div class="step" data-target="#address-step-vertical-modern">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-circle"> </span>
            </button>
          </div>
          <!-- <div class="bs-stepper-line"></div> -->
          <!-- <div class="step" data-target="#social-links-vertical-modern">
            <button type="button" class="step-trigger">
              <span class="bs-stepper-circle"> </span>
            </button>
          </div> -->
        </div>
        <div class="bs-stepper-content p-0">
          <div id="personal-info-vertical-modern" class="content">
            <div class="content-header text-center">
              <h1 class="mb-0 pt-1">¿Qué potencia tienes contratada?</h1>
            </div>

            <div class="row justify-content-center">
              <div class="form-group col-md-12">
                <button
                  type="button"
                  (click)="modernVerticalNext({ power: '2.0' })"
                  class="btn btn-outline-secondary btn-lg btn-block text-bolder"
                  rippleEffect
                >
                  2.0
                </button>
              </div>
              <div class="form-group col-md-12">
                <button
                  type="button"
                  (click)="modernVerticalNext({ power: '3.0' })"
                  class="btn btn-outline-secondary btn-lg btn-block text-bolder"
                  rippleEffect
                >
                  3.0
                </button>
              </div>
              <div class="form-group col-md-12">
                <button
                  type="button"
                  (click)="modernVerticalNext({ power: 'No' })"
                  class="btn btn-outline-secondary btn-lg btn-block text-bolder"
                  rippleEffect
                >
                  No lo sé
                </button>
              </div>
            </div>
            <div class="d-flex pt-50 align-items-center">
              <span class="text-warning mr-50" [data-feather]="'lock'"></span>
              <a
                (click)="openCol(privacypolicy)"
                target="_blank"
                class="text-warning text-bolder"
                >Protegemos tu privacidad</a
              >.
            </div>
          </div>
          <div id="address-step-vertical-modern" class="content">
            <div class="content-header text-center">
              <h1 class="mb-0 pt-1">
                ¿En qué horario consumes más electricidad?
              </h1>
            </div>
            <div class="row justify-content-center">
              <div class="form-group col-md-6">
                <button
                  type="button"
                  (click)="onSubmit({ consume: 'Mañanas' })"
                  class="btn btn-outline-secondary btn-lg text-bolder btn-block"
                  rippleEffect
                >
                  Mañanas
                </button>
              </div>
              <div class="form-group col-md-6">
                <button
                  type="button"
                  (click)="onSubmit({ consume: 'Todo el día' })"
                  class="btn btn-outline-secondary btn-lg text-bolder btn-block"
                  rippleEffect
                >
                  Todo el día
                </button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="form-group col-md-6">
                <button
                  type="button"
                  (click)="onSubmit({ consume: 'Tardes' })"
                  class="btn btn-outline-secondary btn-lg text-bolder btn-block"
                  rippleEffect
                >
                  Tardes
                </button>
              </div>
              <div class="form-group col-md-6">
                <button
                  type="button"
                  (click)="onSubmit({ consume: 'Fines de semana' })"
                  class="btn btn-outline-secondary btn-lg text-bolder btn-block"
                  rippleEffect
                >
                  Fines de semana
                </button>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="form-group col-md-6">
                <button
                  type="button"
                  (click)="onSubmit({ consume: 'Noches' })"
                  class="btn btn-outline-secondary btn-lg text-bolder btn-block"
                  rippleEffect
                >
                  Noches
                </button>
              </div>
            </div>
            <div class="d-flex pt-50 align-items-center">
              <span class="text-warning mr-50" [data-feather]="'lock'"></span>
              <a
                (click)="openCol(privacypolicy)"
                target="_blank"
                class="text-warning text-bolder"
                >Protegemos tu privacidad</a
              >.
            </div>
          </div>
          <!-- <div id="social-links-vertical-modern" class="content">
            <form [formGroup]="firstForm" (ngSubmit)="onSubmit()">
              <div class="content-header text-center">
                <h1 class="mb-0">
                  ¿Cuántos kWh consumes al mes aproximadamente?
                </h1>
              </div>
              <div class="row justify-content-center">
                <div class="form-group col-md-6 mx-auto my-2">
                  <input
                    type="number"
                    id="vertical-modern-twitter"
                    class="form-control"
                    placeholder="100Kwh"
                    formControlName="power"
                  />
                </div>
              </div>
              <div class="content-header text-center">
                <h1 class="mb-0">
                  ¿Cuánto pagas aproximadamente al mes por la luz?
                </h1>
              </div>
              <div class="row">
                <div class="form-group col-md-6 mx-auto my-2">
                  <input
                    type="number"
                    id="vertical-modern-twitter"
                    class="form-control"
                    placeholder="50€"
                    formControlName="howMuchPaid"
                  />
                </div>
              </div>
              <div class="d-flex justify-content-center text-center">
                <button
                  type="submit"
                  class="btn btn-warning btn-submit"
                  rippleEffect
                  [disabled]="!firstForm.valid"
                >
                  Calcular
                </button>
              </div>
            </form>
          </div> -->
        </div>
        <!-- <div class="d-flex flex-column justify-content-between ml-2 mt-3 btn-action-group">
              <button class="btn btn-icon rounded-circle btn-outline-secondary mb-2" (click)="modernVerticalPrevious()" rippleEffect>
                <i data-feather="arrow-up" class="align-middle"></i>
              </button>
              <button class="btn btn-icon rounded-circle btn-outline-secondary" (click)="modernVerticalNext()" rippleEffect>
                <i data-feather="arrow-down" class="align-middle"></i>
              </button>
            </div> -->
      </div>
    </section>
  </div>
  <!-- /Modern Vertical Wizard -->
  <!-- Brands -->
  <section class="w-100" *ngIf="isSubmited && !hidden">
    <div class="card w-100 p-1">
      <div class="card-header text-center">
        <h1>
          Estamos buscando la mejor Tarifa entre todas las compañías del mercado
        </h1>
      </div>
      <div class="card-body">
        <ngb-carousel
          #carousel
          [interval]="1000"
          [pauseOnHover]="pauseOnHover"
          [pauseOnFocus]="pauseOnFocus"
          style="max-height: 100px; max-width: 80px"
          class="mx-auto"
        >
          <ng-template ngbSlide *ngFor="let img of images; index as i">
            <div class="picsum-img-wrapper">
              <img [src]="img" width="80" />
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </section>
  <!-- /Brands -->
  <!-- FORM -->
  <section
    *ngIf="hidden"
    style="width: 570px !important"
    class="last-step-form"
  >
    <div id="stepper1" class="bs-stepper horizontal-wizard-example">
      <div class="bs-stepper-header d-none">
        <div class="step" data-target="#account-details">
          <button class="step-trigger">
            <span class="bs-stepper-box">1</span>
            <span class="bs-stepper-label">
              <span class="bs-stepper-title"
                >REDUCE TU FACTURA Y AHORRA CON LA MEJOR OPCION</span
              >
            </span>
          </button>
        </div>
        <div class="step" data-target="#personal-info">
          <button class="step-trigger">
            <span class="bs-stepper-box">2</span>
            <span class="bs-stepper-label">
              <span class="bs-stepper-title">Personal Info</span>
            </span>
          </button>
        </div>
      </div>
      <div class="bs-stepper-content">
        <form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
          <div id="account-details" class="content">
            <form
              [formGroup]="secondForm"
              (ngSubmit)="horizontalWizardStepperNext()"
            >
              <div class="content-header text-center">
                <h1 class="mb-1">
                  REDUCE TU FACTURA Y AHORRA CON LA MEJOR OPCION
                </h1>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title">Introduce tu CP y Edad</span>
                </span>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <input
                    type="tel"
                    name="zipcode"
                    id="zipcode"
                    formControlName="zipcode"
                    class="form-control"
                    minLength="5"
                    maxLength="5"
                    (keypress)="numberOnly($event)"
                    [ngClass]="{
                      'is-invalid':
                        secondForm.submitted &&
                        secondForm.controls.zipcode.errors
                    }"
                    placeholder="Código postal"
                  />
                  <span
                    *ngIf="
                      secondForm.submitted && secondForm.controls.zipcode.errors
                    "
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="secondForm.controls.zipcode.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
                <div class="form-group col-12">
                  <input
                    required
                    type="number"
                    name="age"
                    min="18"
                    max="100"
                    id="age"
                    formControlName="age"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        secondForm.submitted && secondForm.controls.age.errors
                    }"
                    placeholder="Edad"
                  />
                  <span
                    *ngIf="
                      secondForm.submitted && secondForm.controls.age.errors
                    "
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="secondForm.controls.age.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
              </div>
              <div
                class="row mb-1 p-1"
                style="max-height: 100%; overflow-y: scroll"
              >
                <strong
                  >Información básica sobre protección de datos: RESPONSABLE del
                  tratamiento: E-RETAIL ADVERTISING, S.L., domicilio en calle
                  Ayala 85, 1ºC, Madrid 28006, tel. 911453242</strong
                >
                <p>
                  Fines: prestar los servicios ofrecidos, comunicar sus datos a
                  empresas de energía para ofrecerle el resultado del
                  comparador, elaboración de perfiles para remitirle por
                  nosotros o terceras empresas comunicaciones comerciales. Puede
                  ejercer los derechos de acceso, rectificación, supresión,
                  oposición, portabilidad, limitación y a oponerse a decisiones
                  automatizadas. Puede acceder a la información restante en la
                  <a
                    [routerLink]="['/landings/privacy-policy']"
                    target="_blank"
                    class="text-warning text-bolder"
                    >Politica de privacidad </a
                  >.
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <button
                  [disabled]="!secondForm.valid"
                  type="submit"
                  class="btn btn-warning btn-next btn-block"
                  rippleEffect
                >
                  <span class="align-middle d-sm-inline-block d-none"
                    >Continuar</span
                  >
                  <i
                    data-feather="arrow-right"
                    class="align-middle ml-sm-25 ml-0"
                  ></i>
                </button>
              </div>
              <div class="d-flex pt-50 align-items-center">
                <span class="text-warning mr-50" [data-feather]="'lock'"></span>
                <a
                  (click)="openCol(privacypolicy)"
                  target="_blank"
                  class="text-warning text-bolder"
                  >Protegemos tu privacidad</a
                >.
              </div>
            </form>
          </div>
          <div id="personal-info" class="content">
            <form
              [formGroup]="thirdForm"
              (ngSubmit)="openVerticallyCentered(thankyoupage)"
            >
              <div class="content-header text-center">
                <h1 class="mb-0">
                  REDUCE TU FACTURA Y AHORRA CON LA MEJOR OPCION
                </h1>
                <span class="bs-stepper-label">
                  <span class="bs-stepper-title m-0" style="font-size: 12px"
                    >Te enviaremos el resultado de la mejor compañía de forma
                    gratuita para ti</span
                  >
                </span>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && thirdForm.controls.name.errors
                    }"
                    placeholder="Nombre"
                    formControlName="name"
                  />
                  <span
                    *ngIf="submitted && thirdForm.controls.name.errors"
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="thirdForm.controls.name.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
                <div class="form-group col-12">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        submitted && thirdForm.controls.surname.errors
                    }"
                    placeholder="Apellidos"
                    formControlName="surname"
                  />
                  <span
                    *ngIf="submitted && thirdForm.controls.surname.errors"
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="thirdForm.controls.surname.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
                <div class="form-group col-12">
                  <input
                    type="number"
                    minLength="9"
                    maxLength="9"
                    name="phone"
                    id="phone"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && thirdForm.controls.phone.errors
                    }"
                    placeholder="Móvil"
                    formControlName="phone"
                  />
                  <span
                    *ngIf="submitted && thirdForm.controls.phone.errors"
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="thirdForm.controls.phone.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
                <div class="form-group col-12">
                  <input
                    required
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && thirdForm.controls.email.errors
                    }"
                    placeholder="Email"
                    formControlName="email"
                  />
                  <span
                    *ngIf="submitted && thirdForm.controls.email.errors"
                    class="invalid-form"
                  >
                    <small
                      class="form-text text-danger"
                      *ngIf="thirdForm.controls.email.errors?.required"
                      >Este campo es obligatorio</small
                    >
                  </span>
                </div>
                <div class="form-group col-12 mb-0">
                  <div class="custom-control custom-checkbox mb-0">
                    <input
                      type="checkbox"
                      class="form-control custom-control-input"
                      [ngClass]="{
                        'is-invalid':
                          submitted && thirdForm.controls.terms.errors
                      }"
                      id="terms"
                      name="terms"
                      formControlName="terms"
                    />
                    <label class="custom-control-label text-xs" for="terms"
                      >Acepto la
                      <a
                        (click)="openCol(privacypolicy)"
                        target="_blank"
                        class="text-warning text-bolder"
                        >Politica de privacidad</a
                      >
                      de E-Retail Advertising, S.L. como responsable del
                      tratamiento de mis datos.</label
                    >
                  </div>
                </div>
                <div class="form-group col-12 mb-0">
                  <div class="custom-control custom-checkbox mb-0">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="colaboradores_check"
                      name="colaboradores_check"
                      formControlName="colaboradores"
                      [ng-change]="checkColInputs()"
                    />
                    <label
                      class="custom-control-label text-xs"
                      for="colaboradores_check"
                      >Acepto que mis datos sean tratados para su uso
                      comercial/publicitario mediante el envío de emails, sms,
                      y/o llamadas telefónicas por las empresas del sector
                      energético; haciendo clic en la casilla se marcarán todas
                      las empresas del sector energético, y podré elegirlas y
                      visualizar sus políticas de privacidad
                    </label>
                    <a
                      (click)="openCol(colModal)"
                      target="_blank"
                      class="text-warning text-bolder text-xs"
                      >aquí</a
                    >.
                  </div>
                </div>
                <div class="form-group col-12 mb-0">
                  <div class="custom-control custom-checkbox mb-1">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck10"
                      name="customCheck10"
                    />
                    <label
                      class="custom-control-label text-xs"
                      for="customCheck10"
                      >Acepto que mis datos sean cedidos a empresas de los
                      sectores de actividad de su preferencia que contiene la
                      <a
                        (click)="openCol(privacypolicy)"
                        target="_blank"
                        class="text-warning text-bolder"
                        >Politica de privacidad </a
                      >y sobre los que puede optar aquí, para que puedan
                      remitirle comunicaciones comerciales por cualquier medio,
                      incluidas las llamadas telefónicas
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="row mb-1 p-1"
                style="max-height: 80px; overflow-y: scroll"
              >
                <strong
                  >Información básica sobre protección de datos: RESPONSABLE del
                  tratamiento: E-RETAIL ADVERTISING, S.L., domicilio en calle
                  Ayala 85, 1ºC, Madrid 28006, tel. 911453242</strong
                >
                <p>
                  Fines: prestar los servicios ofrecidos, comunicar sus datos a
                  empresas de energía para ofrecerle el resultado del
                  comparador, elaboración de perfiles para remitirle por
                  nosotros o terceras empresas comunicaciones comerciales. Puede
                  ejercer los derechos de acceso, rectificación, supresión,
                  oposición, portabilidad, limitación y a oponerse a decisiones
                  automatizadas. Puede acceder a la información restante en la
                  <a
                    [routerLink]="['/landings/privacy-policy']"
                    target="_blank"
                    class="text-warning text-bolder"
                    >Politica de privacidad </a
                  >.
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <button
                  type="submit"
                  class="btn btn-warning btn-next btn-block"
                  rippleEffect
                >
                  <span class="align-middle d-sm-inline-block d-none"
                    >Finalizar</span
                  >
                  <i
                    data-feather="arrow-right"
                    class="align-middle ml-sm-25 ml-0"
                  ></i>
                </button>
              </div>
            </form>
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- /FORM -->
</div>

<ng-template #content let-modal>
  <div class="modal-header text-center bg-white">
    <h1 class="modal-title">¡NECESITAMOS TU CONSENTIMIENTO!</h1>
  </div>
  <div class="modal-body">
    <p>
      Acepto de forma expresa que mis datos sean tratados para su uso
      comercial/publicitario (emails, teléfono o sms) por parte del responsable
      del tratamiento E-RETAIL ADVERTISING, S.L, los
      <a (click)="openCol(colaboradores)">Colaboradores</a>, y/o, en su caso, de
      terceros. En todo caso podré ejercer mis derechos de acceso,
      rectificación, supresión, oposición, limitación y portabilidad, en los
      términos expuestos en
      <a [routerLink]="['/landings/privacy-policy']" target="_blank"
        >política de privacidad</a
      >
    </p>
    <div class="d-flex flex-column my-1">
      <a
        class="btn btn-warning btn-lg mb-1"
        (click)="firePixel(modal, '/landings/confirmation')"
      >
        Acepto
      </a>
      <button
        class="btn btn-outline-secondary btn-lg"
        (click)="modal.dismiss('Cross click')"
      >
        No acepto
      </button>
    </div>
  </div>
</ng-template>

<ng-template #thankyoupage let-modal>
  <div class="modal-body">
    <h1 class="modal-title">¡MUCHAS GRACIAS!</h1>
    <h1 class="modal-title">
      EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO PARA OFRECERTE LAS MEJORES
      OFERTAS.
    </h1>
    <p>
      <a
        [routerLink]="['/landings/comparador']"
        (click)="modal.dismiss('Cross click')"
        >Volver al inicio</a
      >
    </p>
  </div>
</ng-template>

<ng-template #colModal let-modal>
  <div class="modal-body">
    <h1 class="modal-title">
      Marcando esta casilla acepto la política de privacidad de:
    </h1>
    <div class="d-flex justify-content-between p-2 flex-column">
      <div class="form-group col-12" *ngIf="colaboradores[0]">
        <div class="custom-control custom-checkbox mb-0">
          <input
            type="checkbox"
            class="custom-control-input"
            [id]="colaboradores[0].title"
            [name]="colaboradores[0].title"
            [(ngModel)]="colaboradores[0].checked"
            [ng-change]="colInputs(colaboradores[0])"
          />
          <!-- <input
            type="checkbox"
            class="custom-control-input"
            id="col001"
            name="col001"
            [(ngModel)]="col_01"
          /> -->
          <label class="custom-control-label" [for]="colaboradores[0].title"
            >Repsol Electricidad y Gas. Más
            <a
              href="https://images.dolead.com/pdf/Repsol+-+Informacio%CC%81n+ba%CC%81sica+sobre+proteccio%CC%81n+de+datos.pdf"
              target="_blank"
              rel="noopener"
              >información</a
            >
            acerca de la
            <a
              href="https://www.repsol.es/particulares/hogar/luz-y-gas/condiciones-potenciales/"
              target="_blank"
              rel="noopener"
              >política de privacidad</a
            >.</label
          >
        </div>
      </div>
      <div class="form-group col-12" *ngIf="colaboradores[1]">
        <div class="custom-control custom-checkbox mb-0">
          <!-- <input
          type="checkbox"
          class="custom-control-input"
          id="col002"
          name="col002"
          [(ngModel)]="col_02"
        /> -->
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="colaboradores[1].checked"
            id="{{ colaboradores[1].title }}"
            name="{{ colaboradores[1].title }}"
            [ng-change]="colInputs(colaboradores[1])"
          />
          <label class="custom-control-label" for="{{ colaboradores[1].title }}"
            >NEXUS ENERGÍA con NIF A-62.332.580 y domicilio en c/ Playa de
            Riazor, 12 OF. 1.5 – 28042 MADRID Más información acerca de la
            <a
              href="https://www.nexusenergia.com/politica-de-privacidad-contratar/"
              target="_blank"
              rel="noopener"
              >política de privacidad</a
            >.</label
          >
        </div>
      </div>
      <div class="form-group col-12" *ngIf="colaboradores[2]">
        <div class="custom-control custom-checkbox mb-0">
          <!-- <input
          type="checkbox"
          class="custom-control-input"
          id="col002"
          name="col002"
          [(ngModel)]="col_02"
        /> -->
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="colaboradores[2].checked"
            id="{{ colaboradores[2].title }}"
            name="{{ colaboradores[2].title }}"
            [ng-change]="colInputs(colaboradores[2])"
          />
          <label class="custom-control-label" for="{{ colaboradores[2].title }}"
            >Escandinava de Electricidad con NIF B85551273 y domicilio en Av.
            Diagonal 463 bis. CP 08036, Barcelona. Acerca de la
            <a
              href="https://escandinavaelectricidad.es/politica-de-privacidad"
              target="_blank"
              rel="noopener"
              >política de privacidad</a
            >.</label
          >
        </div>
      </div>
      <div class="form-group col-12" *ngIf="colaboradores[3]">
        <div class="custom-control custom-checkbox mb-0">
          <!-- <input
          type="checkbox"
          class="custom-control-input"
          id="col002"
          name="col002"
          [(ngModel)]="col_02"
        /> -->
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="colaboradores[3].checked"
            id="{{ colaboradores[3].title }}"
            name="{{ colaboradores[3].title }}"
            [ng-change]="colInputs(colaboradores[3])"
          />
          <label class="custom-control-label" for="{{ colaboradores[3].title }}"
            >HOLALUZ-CLIDOM, S.A. (“Holaluz”) CIF: A65445033 Dirección postal:
            Passeig Joan de Borbó 99-101 4ª Planta, 08039
            <a
              href="https://www.contratarholaluz.com/politica-privacidad"
              target="_blank"
              rel="noopener"
              >política de privacidad</a
            >.</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a (click)="modal.dismiss('Cross click')">Aceptar</a>
  </div>
</ng-template>

<ng-template #privacypolicy let-modal>
  <div class="modal-body">
    <app-landings-privacy-policy></app-landings-privacy-policy>
  </div>
  <div class="modal-footer">
    <a (click)="modal.dismiss('Cross click')">Aceptar</a>
  </div>
</ng-template>
